exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agentuur-js": () => import("./../../../src/pages/agentuur.js" /* webpackChunkName: "component---src-pages-agentuur-js" */),
  "component---src-pages-cookiepolicy-js": () => import("./../../../src/pages/cookiepolicy.js" /* webpackChunkName: "component---src-pages-cookiepolicy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-teenused-epood-js": () => import("./../../../src/pages/teenused/epood.js" /* webpackChunkName: "component---src-pages-teenused-epood-js" */),
  "component---src-pages-teenused-graafiline-disain-js": () => import("./../../../src/pages/teenused/graafiline-disain.js" /* webpackChunkName: "component---src-pages-teenused-graafiline-disain-js" */),
  "component---src-pages-teenused-html-5-bannerid-js": () => import("./../../../src/pages/teenused/html5bannerid.js" /* webpackChunkName: "component---src-pages-teenused-html-5-bannerid-js" */),
  "component---src-pages-teenused-js": () => import("./../../../src/pages/teenused.js" /* webpackChunkName: "component---src-pages-teenused-js" */),
  "component---src-pages-teenused-kodulehed-js": () => import("./../../../src/pages/teenused/kodulehed.js" /* webpackChunkName: "component---src-pages-teenused-kodulehed-js" */),
  "component---src-pages-teenused-logo-kujundamine-js": () => import("./../../../src/pages/teenused/logo-kujundamine.js" /* webpackChunkName: "component---src-pages-teenused-logo-kujundamine-js" */),
  "component---src-pages-teenused-maandumislehed-js": () => import("./../../../src/pages/teenused/maandumislehed.js" /* webpackChunkName: "component---src-pages-teenused-maandumislehed-js" */),
  "component---src-pages-teenused-mobiilirakendused-js": () => import("./../../../src/pages/teenused/mobiilirakendused.js" /* webpackChunkName: "component---src-pages-teenused-mobiilirakendused-js" */),
  "component---src-pages-teenused-piiramatult-disaini-js": () => import("./../../../src/pages/teenused/piiramatult-disaini.js" /* webpackChunkName: "component---src-pages-teenused-piiramatult-disaini-js" */),
  "component---src-pages-teenused-ui-ux-disain-js": () => import("./../../../src/pages/teenused/ui-ux-disain.js" /* webpackChunkName: "component---src-pages-teenused-ui-ux-disain-js" */),
  "component---src-pages-teenused-uudiskirjad-js": () => import("./../../../src/pages/teenused/uudiskirjad.js" /* webpackChunkName: "component---src-pages-teenused-uudiskirjad-js" */),
  "component---src-pages-teenused-wordpress-arendus-js": () => import("./../../../src/pages/teenused/wordpress-arendus.js" /* webpackChunkName: "component---src-pages-teenused-wordpress-arendus-js" */),
  "component---src-templates-artiklid-js": () => import("./../../../src/templates/artiklid.js" /* webpackChunkName: "component---src-templates-artiklid-js" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-11-nippi-kuidas-internetis-turundada-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/11-nippi-kuidas-internetis-turundada/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-11-nippi-kuidas-internetis-turundada-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-2024-ennustused-veebilehtede-tegemise-ja-digiturundusega-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/2024-ennustused-veebilehtede-tegemise-ja-digiturundusega/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-2024-ennustused-veebilehtede-tegemise-ja-digiturundusega-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-2024-horoskoop-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/2024-horoskoop/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-2024-horoskoop-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-21-ideed-epoele-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/21-ideed-epoele/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-21-ideed-epoele-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-branding-kui-ettevotte-identiteedi-loomeprotsess-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/branding-kui-ettevotte-identiteedi-loomeprotsess/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-branding-kui-ettevotte-identiteedi-loomeprotsess-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-digi-vs-printreklaam-vaikeettevotjale-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/digi-vs-printreklaam-vaikeettevotjale/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-digi-vs-printreklaam-vaikeettevotjale-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-disaini-sprint-taskily-index-en-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/disaini-sprint-taskily/index-en.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-disaini-sprint-taskily-index-en-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-disaini-sprint-taskily-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/disaini-sprint-taskily/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-disaini-sprint-taskily-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-framer-love-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/framer-love/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-framer-love-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-glossary-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/glossary/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-glossary-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-graafiline-disain-kui-konkurentsieelis-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/graafiline-disain-kui-konkurentsieelis/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-graafiline-disain-kui-konkurentsieelis-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-hea-logo-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/hea-logo/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-hea-logo-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-hea-visiitkaart-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/hea-visiitkaart/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-hea-visiitkaart-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-investorikolbulik-koduleht-index-en-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/investorikolbulik-koduleht/index-en.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-investorikolbulik-koduleht-index-en-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-investorikolbulik-koduleht-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/investorikolbulik-koduleht/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-investorikolbulik-koduleht-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kasutajakeskne-ui-ux-disain-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kasutajakeskne-ui-ux-disain/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kasutajakeskne-ui-ux-disain-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kasutajasobraliku-ui-ux-disaini-5-punkti-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kasutajasobraliku-ui-ux-disaini-5-punkti/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kasutajasobraliku-ui-ux-disaini-5-punkti-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kiiresti-kvaliteetselt-soodsalt-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kiiresti-kvaliteetselt-soodsalt/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kiiresti-kvaliteetselt-soodsalt-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kkk-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kkk/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kkk-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-konversioonimaara-optimeerimine-index-en-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kodulehe-konversioonimäära-optimeerimine/index-en.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-konversioonimaara-optimeerimine-index-en-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-konversioonimaara-optimeerimine-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kodulehe-konversioonimäära-optimeerimine/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-konversioonimaara-optimeerimine-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-optimeerimine-ux-2-x-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kodulehe-optimeerimine-ux-2x/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-optimeerimine-ux-2-x-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-tegemine-osa-1-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kodulehe-tegemine-osa-1/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-tegemine-osa-1-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-tegemine-osa-2-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kodulehe-tegemine-osa-2/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-tegemine-osa-2-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-tegemine-osa-3-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kodulehe-tegemine-osa-3/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kodulehe-tegemine-osa-3-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kodulehtede-olukord-aastaks-2024-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kodulehtede-olukord-aastaks-2024/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kodulehtede-olukord-aastaks-2024-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-kuidas-suurendada-myyki-kodulehega-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/kuidas-suurendada-myyki-kodulehega/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-kuidas-suurendada-myyki-kodulehega-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-mida-postitada-sotsiaalmeediasse-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/mida-postitada-sotsiaalmeediasse/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-mida-postitada-sotsiaalmeediasse-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-miks-disain-on-oluline-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/miks-disain-on-oluline/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-miks-disain-on-oluline-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-miks-tellida-kodulehe-kriitika-index-en-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/miks-tellida-kodulehe-kriitika/index-en.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-miks-tellida-kodulehe-kriitika-index-en-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-miks-tellida-kodulehe-kriitika-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/miks-tellida-kodulehe-kriitika/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-miks-tellida-kodulehe-kriitika-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-mis-on-no-code-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/mis-on-noCode/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-mis-on-no-code-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-mis-on-seo-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/mis-on-seo/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-mis-on-seo-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-mitmekeelne-koduleht-frameris-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/mitmekeelne-koduleht-frameris/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-mitmekeelne-koduleht-frameris-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-piiramatu-disainipaketi-head-ja-vead-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/piiramatu-disainipaketi-head-ja-vead/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-piiramatu-disainipaketi-head-ja-vead-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-pohilised-kodulehtede-probleemid-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/pohilised-kodulehtede-probleemid/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-pohilised-kodulehtede-probleemid-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-psuhholoogia-veebidisainis-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/psuhholoogia-veebidisainis/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-psuhholoogia-veebidisainis-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-punktid-kodulehel-tahelepanu-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/punktid-kodulehel-tahelepanu/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-punktid-kodulehel-tahelepanu-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-rebranding-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/rebranding/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-rebranding-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-teade-frameri-ekspert-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/teade-frameri-ekspert/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-teade-frameri-ekspert-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-tee-nii-ja-graafiline-disain-onnestub-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/tee-nii-ja-graafiline-disain-onnestub/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-tee-nii-ja-graafiline-disain-onnestub-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-templiit-veebid-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/templiit-veebid/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-templiit-veebid-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-ui-ux-tellimise-19-punkti-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/ui-ux-tellimise-19-punkti/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-ui-ux-tellimise-19-punkti-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-ux-seadused-osa-1-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/ux-seadused-osa1/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-ux-seadused-osa-1-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-veebianimatsioonid-kodulehel-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/veebianimatsioonid-kodulehel/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-veebianimatsioonid-kodulehel-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-kujundus-ja-sisu-index-en-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/veebilehe-kujundus-ja-sisu/index-en.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-kujundus-ja-sisu-index-en-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-kujundus-ja-sisu-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/veebilehe-kujundus-ja-sisu/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-kujundus-ja-sisu-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-optimeerimine-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/veebilehe-optimeerimine/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-optimeerimine-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-rost-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/veebilehe-rost/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-rost-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-seo-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/veebilehe-seo/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-seo-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-tegemine-millest-alustada-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/veebilehe-tegemine-millest-alustada/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-veebilehe-tegemine-millest-alustada-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-visiitkaardi-moodud-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/visiitkaardi-moodud/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-visiitkaardi-moodud-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-webflow-tutvustus-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/webflow-tutvustus/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-webflow-tutvustus-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-wehype-kodulehe-tegemin-frameris-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/wehype-kodulehe-tegemin-frameris/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-wehype-kodulehe-tegemin-frameris-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-content-blog-wow-webflow-uuendused-index-mdx": () => import("./../../../src/templates/SinglePost.js?__contentFilePath=/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/wow-webflow-uuendused/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-content-blog-wow-webflow-uuendused-index-mdx" */)
}

